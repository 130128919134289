@import "../../stylesheets/vars";
@import "../../stylesheets/type";
@import "../../stylesheets/table";

.card {
	display: block;
	border: 1px solid rgba($text, 0.24);
	border-radius: 4px;
	.card-header {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	&.is-horizontal {
		// display: flex;
		.card-image {
			width: 40%;
			height: auto;
			background-size: cover;
			background-position: center center;
		}
	}
}

.is-sidebar {
	background: #fff;
	border-right: 1px solid rgba($text, 0.2);
	height: 100vh;
	overflow-y: scroll;
	// aside.menu {
	//   position: fixed;
	// }
}
.is-content {
	height: calc(100vh);
	overflow-y: scroll;
	overflow-x: hidden;
}

@import "bulma";

svg {
	min-height: 16px;
	min-width: 16px;
}

.inline-svg {
	height: 16px;
	width: 16px;
	position: relative;
	top: 3px;
	margin-right: 2px;
}

.section:not(:first-of-type) {
	padding-top: 1.5rem;
}

.editor-class {
	background: #fff;
	border: 1px solid #f1f1f1;
	padding: 0 8px;
	border-radius: 2px;
}
