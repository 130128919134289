@charset "utf-8";

// Import a Google Font
// @import "../fonts/_fonts"

// Set your brand colors
$primary: #1C2A48;
$bg-color: #F8F8F9;
$richmond: #be1fa0;
$danger: red;

.has-background-white-bis {
    background: #F8F8F9;
}

label.label {
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: normal;
}

.input, .select select {
    border: 1px solid rgba(28, 42, 72, 0.24) !important;
    border-color: rgba(28, 42, 72, 0.24) !important;
    box-shadow: none !important;
}

.input:hover, .select select:hover {
    border-color: rgba(28, 42, 72, 0.4) !important;
}

.is-danger.input, .is-danger.textarea {
    border-color: #ff3860 !important;
}

.file-cta {
    background: white;
    background-position: center;
    background-size: cover;
    border: 1px dashed rgba(28, 42, 72, 0.24) !important;
    min-height: 160px;
    justify-content: center;
}

.file-cta.is-danger {
    border: 1px dashed red !important;
}

.checkbox input[type="checkbox"] {
    position: relative;
    margin-top: 3px;
    margin-right: 4px;
    vertical-align: top;
    border: none;
    outline: none;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
}

.checkbox input[type="checkbox"]::before, .checkbox input[type="checkbox"]::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    -webkit-transition: 240ms;
    -o-transition: 240ms;
    transition: 240ms;
}

.checkbox input[type="checkbox"]::after {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    -webkit-transition: 240ms;
    -o-transition: 240ms;
    transition: 240ms;
    position: relative;
    top: -3px;
    border: 1px solid #1C2A48;
}

.checkbox input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 6px;
    display: table;
    width: 5px;
    height: 10px;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg) scale(0);
    transform-origin: center;
    transition: all .25s ease;
}

.checkbox input[type="checkbox"]:checked::after {
    border-color: #1C2A48;
}

.checkbox input[type="checkbox"]:checked::before {
    border: 2px solid #1C2A48;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg) scale(1);
}

.checkbox.is-white input[type="checkbox"]::after {
    border: 1px solid white;
}

.checkbox.is-white input[type="checkbox"]:checked::after {
    border-color: white;
}

.checkbox.is-white input[type="checkbox"]:checked::before {
    border-color: white;
}

.has-opacity-85 {
    opacity: 0.85;
}

.has-opacity-65 {
    opacity: 0.65;
}

.has-opacity-45 {
    opacity: 0.45;
}

.is-aligned-bottom {
    align-items: flex-end;
}

.is-flex-column {
    flex-direction: column;
}

.is-justified-center {
    justify-content: center;
}

.broward-header {
    padding-top: 24px;
    padding-bottom: 24px;
    background-size: cover;
    background-position: center 70%;
    background-repeat: no-repeat;
    position: relative;
}

.broward-header::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(28, 42, 72, 0.44);
}

.broward-header.arl::after {
    background: rgba(36, 125, 214, 0.84);
}

.broward-header.richmond::after {
    background: rgba(190, 31, 160, 0.82);
}

.broward-header .container {
    position: relative;
    z-index: 2;
}

.broward-logo img {
    height: 80px;
}

.license-row:hover {
    background: rgba(28, 42, 72, 0.05);
}

.progress-tabs {
    margin-bottom: 32px !important;
}

.progress-tabs > div {
    opacity: 0.32;
}

.progress-tabs > div.active {
    opacity: 1;
}

.progress-tabs > div > span {
    background: #1C2A48;
    height: 4px;
    width: 100%;
    display: block;
    border-radius: 4px;
}

.tab-content {
    display: none !important;
}

.tab-content.active {
    display: flex !important;
}

.radio-image img {
    height: auto;
}

.radio-image input {
    display: none;
    opacity: 1;
}

.radio-image input + div {
    opacity: 1;
    transition: all .25s ease;
}

.radio-image input:not(:checked) + div {
    opacity: 0.4;
    filter: grayscale(1);
}

.price {
    justify-content: space-between;
    padding: 0.75rem;
}

.digital-certification.inactive {
    opacity: 0.4;
    pointer-events: none;
}

.mailing-address {
    display: none;
}

.mailing-address.active {
    display: block;
}

.terms-card .card-header {
    padding: 2.25rem;
    position: relative;
    box-shadow: none !important;
}

.terms-card .card-header p {
    flex-grow: 2;
    position: relative;
    z-index: 3;
}

.terms-card .card-header img {
    position: absolute;
    height: auto;
    width: 140px;
    bottom: 0;
}

.terms-card .card-header img:last-of-type {
    right: 80px;
}

.terms-card .card-header img:first-of-type {
    left: 80px;
}

.terms-card .card-content {
    overflow: scroll;
}

@media (max-width: 768px) {
    .terms-card .card-header img:last-of-type {
        right: 24px;
    }
    .terms-card .card-header img:first-of-type {
        left: 24px;
    }
}

.broward-sort .card {
    height: 100%;
}

.broward-sort .card .card-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tag-status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    background: blue;
    margin-right: 4px;
}

.tag-status.tag-active {
    background: #1CB8A4;
}

.tag-status.tag-inactive {
    background: #EF8484;
}

.StripeElement {
    padding: 8px 4px;
    color: #32325d;
    background-color: white;
    border: 1px solid rgba(28, 42, 72, 0.24) !important;
    border-radius: 4px;
}

.StripeElement--focus {
    border: 1px solid rgba(28, 42, 72, 0.64) !important;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.ElementsApp, .ElementsApp .InputElement {
    height: auto;
}
