@import '../../stylesheets/vars';

.profile-image {
  .profile-image__upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    opacity: 0.64;
  }
  &:hover {
    .profile-image__upload {
      display: flex;
    }
  }
}
